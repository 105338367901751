import * as Tokens from '@atlaskit/tokens';
import globalize from '../internal/globalize';

import './design-tokens-init';

// Export for NPM bundle, for which Webpack automatically puts those functions under AJS.DesignTokens
export const {
    setGlobalTheme
} = Tokens;

// Export for p2-plugin bundle, where we need to do put those functions under AJS.DesignTokens manually
globalize('DesignTokens', {
    setGlobalTheme
});
