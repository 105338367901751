export default {
	'light': () => import(
		/* webpackChunkName: "./themes/light" */
		'./themes/light.js'
	),
	'dark': () => import(
		/* webpackChunkName: "./themes/dark" */
		'./themes/dark.js'
	),
	'legacy-light': () => import(
		/* webpackChunkName: "./themes/legacy-light" */
		'./themes/legacy-light.js'
	),
	'legacy-dark': () => import(
		/* webpackChunkName: "./themes/legacy-dark" */
		'./themes/legacy-dark.js'
	),
	'spacing': () => import(
		/* webpackChunkName: "./themes/spacing" */
		'./themes/spacing.js'
	),
	'typography': () => import(
		/* webpackChunkName: "./themes/typography" */
		'./themes/typography.js'
	),
	'shape': () => import(
		/* webpackChunkName: "./themes/shape" */
		'./themes/shape.js'
	),
	'typography-adg3': () => import(
		/* webpackChunkName: "./themes/typography-adg3" */
		'./themes/typography-adg3.js'
	),
	'typography-minor3': () => import(
		/* webpackChunkName: "./themes/typography-minor3" */
		'./themes/typography-minor3.js'
	),
	'light-new-input-border': () => import(
		/* webpackChunkName: "./themes/light-new-input-border" */
		'./themes/light-new-input-border.js'
	),
	'dark-new-input-border': () => import(
		/* webpackChunkName: "./themes/dark-new-input-border" */
		'./themes/dark-new-input-border.js'
	),
	'original': () => Promise.resolve(''),
};