(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@atlassian/aui", [], factory);
	else if(typeof exports === 'object')
		exports["@atlassian/aui"] = factory();
	else
		root["AJS"] = root["AJS"] || {}, root["AJS"]["DesignTokens"] = factory();
})(window, () => {
return 